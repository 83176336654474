<script>
import { computed } from 'vue'
import { useRouter } from 'vue-router/composables'
import ui from '/~/core/ui'
import { useCommunityHub } from '/~/extensions/community-hub/composables'
import cdn from '/~/utils/cdn'
import BaseBack from '/~/components/base/back/base-back.vue'
import BaseButton from '/~/components/base/button/base-button.vue'
import BaseImage from '/~/components/base/image/base-image.vue'
import BaseLink from '/~/components/base/link/base-link.vue'
import EwDetailsHeader from '/~/components/ewallet/ew-details-header.vue'

export default {
  name: 'community-hub-offers-redirect',
  components: {
    EwDetailsHeader,
    BaseLink,
    BaseButton,
    BaseBack,
    BaseImage,
  },
  props: {
    id: {
      type: String,
      required: true,
    },
    retailer: {
      type: Object,
      default: null,
    },
    promotionUrl: {
      type: String,
      default: null,
    },
    content: {
      type: String,
      default: null,
    },
    offer: {
      type: String,
      default: null,
    },
  },
  setup(props) {
    const router = useRouter()
    const { moduleName, redeemOffer } = useCommunityHub()

    if (!props.retailer) {
      router.push({
        name: `${moduleName}-retailer`,
        params: {
          id: props.id,
        },
      })
    }

    const name = computed(() => props.retailer?.name)
    const logo = computed(() => ({
      url: cdn(props.retailer?.logo ?? '')
        .resize('400x')
        .url(),
      alt: name.value,
    }))

    function onClick() {
      const { offer } = props

      if (offer) {
        let offerId

        if (typeof offer === 'string') {
          offerId = offer
        } else {
          offerId = offer.id
        }

        try {
          redeemOffer(offerId)
        } catch (error) {
          console.error('community-hub', error)
        }
      }
    }

    return {
      onClick,
      logo,
      name,
      ui,
    }
  },
}
</script>

<template>
  <div class="h-full p-5 pb-11 sm:p-[30px] sm:pt-5">
    <div v-if="!ui.mobile">
      <base-back />
    </div>
    <ew-details-header :title="name" :loading="false" />
    <div class="m-auto space-y-[30px] text-center">
      <div class="mx-auto my-11 max-h-40 max-w-40 shrink-0">
        <base-image :src="logo.url" :alt="logo.alt" :ratio="1" />
      </div>

      <h1 class="mb-5 text-2xl font-bold sm:text-3xl">
        You are now being redirected to retailer's website
      </h1>

      <p class="mx-auto w-xse max-w-full">
        {{ content }}
      </p>

      <base-link v-if="promotionUrl" class="block" :href="promotionUrl">
        <base-button full-width @click="onClick">Go to website</base-button>
      </base-link>
    </div>
  </div>
</template>
